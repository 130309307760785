<template>
  <ion-page>
    <Main>
      <template #subHeader>
        <Head></Head>
        <ion-row class="tabs-container">
          <ion-col @click="reload('new')">
            <span class="tab" :class="{ active: controlers.tab == 'new' }">
              Productos
            </span>
          </ion-col>
          <ion-col>
            <ion-input class="ion-no-padding ion-padding-horizontal ion-text-start" placeholder="Buscar..." v-model="search"></ion-input>
          </ion-col>
        </ion-row>
      </template>
      <template #content>
        <template v-for="(product, o) in products" :key="o">
          <Product v-if="product.label.includes(search)" :product="product" @getProducts="getProducts()"/>
        </template>
      </template>
    </Main>
  </ion-page>
</template>

<script>
import { IonPage, IonRow, IonCol, IonInput, alertController } from "@ionic/vue";
import Main from "@/components/system/main.vue";
import Head from "@/views/system/almacen/head.vue";
import Product from "@/views/system/almacen/product.vue";
import { ref } from "@vue/reactivity";
import useApi from "@/use/useApi";
export default {
  name: "Products",
  components: {
    Main,
    Head,
    Product,
    IonPage,
    IonRow,
    IonCol,
    IonInput,
  },
  setup() {
    const search = ref('');
    const controlers = ref({
      tab: "new",
    });
    const products = ref([]);
    const { post } = useApi();
    async function getProducts() {
      const data = await post(
        "app/almacen/get-all",
        { done: controlers.value.tab == "new" ? 0 : 1 },
        "Obteniendo información"
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          products.value = data.products ? data.products : [];
        } else {
          products.value = [];
        }
      }
    }
    function reload(tab) {
      controlers.value.tab = tab;
      getProducts();
    }

    getProducts();
    return { search, products, controlers, reload, getProducts };
  },
};
</script>

<style>
.tabs-container {
  background: var(--ion-color-light);
}
</style>
