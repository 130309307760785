<template>
  <ion-row>
    <ion-col>
      <ion-card>
        <ion-card-header>
          <ion-row>
            <ion-col>
              <ion-card-title>
                Orden de Producción {{ $filters.folio(product.id_production) }}
              </ion-card-title>
              <ion-card-subtitle>
                <p class="subtitle mt-1">
                  Fecha de Solicitud: {{ product.status }}
                  <span class="ion-primary-color">
                    {{ $filters.timeFrom(product.date) }}</span
                  >
                </p>
              </ion-card-subtitle>
            </ion-col>
            <ion-col size="3" class="ion-text-end">
              <span
                class="status"
                :class="{
                  solicitado: product.revision == 0,
                  produccion: product.revision == 1,
                }"
                >{{
                  $filters.capitalize(
                   product.revision == "0"
                      ? "revision"
                      : "terminado"
                  )
                }}</span
              >
            </ion-col>
          </ion-row>
        </ion-card-header>
        <ion-card-content>
          <ion-row class="tab-head">
            <ion-col size="6"> </ion-col>
            <ion-col size="6">
              <p>
                Fecha:
                <span class="ion-primary-color pull-right">
                  {{ moment(product.d_date).format("DD MMM YYYY") }}
                </span>
              </p>
            </ion-col>
          </ion-row>
          <ion-row class="tab-row">
            <ion-col size="6">
              <ion-row class="ion-no-padding">
                <ion-col size="2">
                  <p>
                    <span class="number">
                      1
                    </span>
                  </p>
                </ion-col>

                <ion-col size="10">
                  <p v-if="product.name" class="tab-row-sub">
                    {{
                      product.name
                        .replace(product.capacity, "")
                        .replace("Lts.", "")
                    }}
                  </p>
                </ion-col>
                <ion-col size="12">
                  <p class="subtitle2 margin-left-col2">
                    {{
                      product.label
                        ? "#" + product.label
                        : $filters.folio(product.id)
                    }}
                    <span class="pull-right">
                      {{ product.segunda == 1 ? "Segunda" : "Primera" }}
                    </span>
                  </p>
                </ion-col>
              </ion-row>
            </ion-col>
            <ion-col size="2">
              <p class="p4 tab-row-sub">{{ product.capacity }} Lts.</p>
            </ion-col>
            <ion-col size="4">
              <ion-button
                type="button"
                class="ion-margin-bottom btn-edit"
                expand="block"
                color="outlined"
                @click="select(product.id)"
              >
                <ion-icon :icon="create"></ion-icon>
              </ion-button>
            </ion-col>
          </ion-row>
        </ion-card-content>
      </ion-card>
    </ion-col>
  </ion-row>
</template>

<script>
import moment from "moment-business-days";
import {
  IonRow,
  IonCol,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardSubtitle,
  IonCardContent,
  IonButton,
  IonIcon,
  alertController,
} from "@ionic/vue";
import { create } from "ionicons/icons";
import { toRefs } from "@vue/reactivity";
import useApi from "@/use/useApi";
export default {
  components: {
    IonRow,
    IonCol,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardSubtitle,
    IonCardContent,
    IonButton,
    IonIcon,
  },
  props: {
    product: Object,
  },
  setup(props, { emit }) {
    const { product } = toRefs(props);
    const { post } = useApi();

    async function passProd() {
      const data = await post(
        "app/almacen/confirm",
        {
          id: product.value.id,
        },
        "..."
      );
      if (data === null) {
        const alert = await alertController.create({
          class: "warning",
          header: "Error",
          message: "Se genero un error en servidor",
        });
        await alert.present();
      } else {
        if (data.error === false) {
          const alert = await alertController.create({
            class: "success",
            header: "Exito!",
            message: data.message,
          });
          await alert.present();
          const done = await alert.onDidDismiss();
          if (done) {
            emit("getProducts");
          }
        }
      }
    }

    async function select() {
      const alert = await alertController.create({
        class: "success",
        header: "Registrar paso de producto",
        message: "¿Continuar?",
        buttons: [
          {
            text: "Cancelar",
            handler: () => {
              console.log("Cancel");
            },
          },
          {
            text: "Continuar",
            handler: () => {
              passProd();
            },
          },
        ],
      });
      await alert.present();
    }
    return { moment, create, select };
  },
};
</script>

<style>
ion-card {
  border-radius: 15px;
  border: 1px solid var(--ion-color-primary);
  box-shadow: none;
  font-size: 12px;
}
ion-card-title {
  color: var(--ion-color-medium);
  font-size: 14px;;
}
.btn-edit::part(native) {
  background: #fff;
  box-shadow: none;
  border: 1px solid var(--ion-color-medium-tint);
  color: var(--ion-color-medium-tint);
  padding: 20px 10px;
  transform: translateY(1px);
  width: auto;
  float: right;
}
</style>
