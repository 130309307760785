<template>
  <ion-toolbar  color="light">
    <ion-row>
      <ion-col>
        <ion-title class="text-center ion-no-padding">
          {{ user.name }} {{ user.lastname }}
        </ion-title>
      </ion-col>
      <ion-col>
        <ion-title class="text-center ion-no-padding"> Almacen </ion-title>
      </ion-col>
      <ion-col>
        <ion-title class="text-center ion-no-padding">
          {{ day }}
        </ion-title>
      </ion-col>
    </ion-row>
  </ion-toolbar>
</template>
<script>
import moment from "moment-business-days";
import useAuth from "@/providers/auth";
import { IonToolbar, IonTitle, IonRow, IonCol } from "@ionic/vue";
export default {
  name: "headTabs",
  components: {
    IonToolbar,
    IonTitle,
    IonRow,
    IonCol,
  },
  setup(props) {
    moment.locale("es");
    const { user } = useAuth();
    const day = moment().format('DD/MM/yyyy');
    return { user, day };
  },
};
</script>

<style>
.text-center {
  font-size: 12px;
  color: var(--ion-color-medium);
  font-weight: bold;
}
</style>
